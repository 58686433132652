const prod = process.env.NODE_ENV === 'production';

const backendUrl = 'https://api.reanlotheapp.com/v1/';
// const backendUrl = 'https://dev-api.reanlotheapp.com/v1/';
// const backendUrl = 'http://192.168.1.92:3000/v1/';

export default {
  siteName: 'React',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} test`,

  apiUrl: backendUrl,
  csvUrl: prod ? `SampleCsv` : 'http://192.168.2.191:1337/SampleCsv',
  domainUrl: prod
    ? 'http://dev-admin.reanlotheapp.com'
    : 'http://localhost:3000',
  sailsUrl: '',

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  // tinyEditorKey: 'ol3fha1jhzkbbmi55gzts8q3i1f5sp7kqapqubwqu6nr81vc',
  tinyEditorKey: '6rmm6a14vf0jr7p1v8d8n76dsndxof78dojvvq3t9qylrzx1',
  dashboard: '/dashboard',
};
